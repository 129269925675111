import React from 'react'

import Logo from '../../assets/images/logo/logo.png'
import Fb from '../../assets/images/footer/fb.png'
import Insta from '../../assets/images/footer/insta.png'
import Youtube from '../../assets/images/footer/youtube.png'
// import Twitter from '../../assets/images/footer/twitter.png'
// import Linkedin from '../../assets/images/footer/linkedin.png'

import { ReactComponent as Location } from '../../assets/images/svg/location.svg'
import { ReactComponent as Email } from '../../assets/images/svg/email.svg'
// import { ReactComponent as Mobile } from '../../assets/images/svg/mobile.svg'
import { ReactComponent as Contact } from '../../assets/images/svg/contact.svg'
import { ReactComponent as Fax } from '../../assets/images/svg/fax.svg'

const Footer = () => {
  return (
    <div className='Footer'>
      <div className='Footer__container'>
        <div className='Footer__item'>
          <img src={Logo} alt='logo' />
        </div>
        <div className='Footer__item'>
          <h5>ABOUT US </h5>
          <p>
            <Location />Geojet Logistics Services, Inc: 3601-3602 Cityland Pasong Tamo Tower, 2210 Chino Roces
            Avenue, Makati City, Philippines
          </p>
          <p>
            <Location />Cebu Branch: MHT Residences, Unit 103, Plaridel Street, Carambo, Mandaue City, Philippines
          </p>
          <p>
            <Email /> sales@geojet.com.ph
          </p>
        </div>
        <div className='Footer__item'>
          <h5>CONTACT NUMBERS </h5>
          {/* <p>
            <Mobile /> + 63 917 8338825
          </p> */}
          <p>
            {/* <Contact /> + 632 88928000 */}
            Accounting: +63 917 1426 709 
          </p>
          <p>
            Operations: +63 917 1429 714 
          </p>
          <p>
            Sales: +63 917 1399 083 
          </p>
          {/* <p>
            <Fax /> + 632 88121654
          </p> */}
        </div>
        <div className='Footer__social'>
          <h5>FOLLOW US </h5>
          <a href='https://www.facebook.com/GeojetLogisticsServicesInc' target='_blank' rel='noreferrer'>
            <img src={Fb} alt='fb' />
          </a>
          <a href='https://www.instagram.com/geojet_logistics/' target='_blank' rel='noreferrer'>
            <img src={Insta} alt='insta' />
          </a>
          <a href='https://www.youtube.com/@geojetlogisticsservicesinc5849/videos' target='_blank' rel='noreferrer'>
            <img src={Youtube} alt='youtube' />
          </a>
          {/* <a href='/' target='_blank'>
            <img src={Twitter} alt='twitter' />
          </a> */}
          {/* <a href='/' target='_blank'>
            <img src={Linkedin} alt='linkedin' />
          </a> */}
        </div>
      </div>
      <div className='Footer__copyright'>
        <p>Copyright © {(new Date().getFullYear())}. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer
